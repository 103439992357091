<template>
  <div class="cart-item">
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      class="d-flex flex-row flex-wrap justify-space-between align-center"
      :class="isInPromo ? promoClass : ''"
      @click="updatedDrawerRight(false)"
    >
      <div
        v-if="
          product.warehousePromo &&
            product.warehousePromo.view &&
            product.warehousePromo.view.bubble &&
            isInPromo
        "
        class="promo_bubble"
        v-html="item.product.warehousePromo.view.bubble"
      ></div>
      <img
        width="70"
        height="70"
        :src="item.product.mediaURL"
        :alt="item.product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
        class="my-0"
      />
      <v-list-item-content class="flex-grow px-1">
        <div class="text-body-2">
          {{ item.product.name }}
        </div>
        <v-list-item-subtitle
          class="font-weight-bold text-uppercase text-body-2"
        >
          {{ item.product.shortDescr }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-uppercase">
          {{ item.product.description }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="item.cartItemInfo.size"
          class="cart-item-info"
        >
          <i>{{ $t("product.preferences") }}: {{ item.cartItemInfo.size }}</i>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          v-if="
            itemAdjustment &&
              itemAdjustment.userGiftCertificateId &&
              itemAdjustment.giftCertificate
          "
          class="cart-item-info promo--text"
        >
          <em>{{ itemAdjustment.giftCertificate.name }}: </em>
          <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action class="d-flex flex-row align-center ml-0 ml-sm-2">
        <div class="promo-wrapper-product-card">
          <div
            v-if="item.product.warehousePromo && isInPromo"
            class="product_promo"
            :class="product.warehousePromo.view.cssClass"
          >
            <div
              class="promo_header"
              v-html="item.product.warehousePromo.view.header"
            ></div>
            <div
              class="promo_body"
              v-html="item.product.warehousePromo.view.body"
            ></div>
            <div
              class="promo_footer"
              v-html="item.product.warehousePromo.view.footer"
            ></div>
          </div>
        </div>
        <div
          v-if="item.editable"
          class="d-flex align-center w-100 gap-1 flex-wrap"
          :class="
            $vuetify.breakpoint.xs ? 'justify-end' : 'justify-space-between'
          "
        >
          <ProductPrice
            v-if="full"
            :product="item.product"
            :showStandardPrice="isInPromo"
          />
          <ProductQty :item="item" :product="item.product" :updateCart="true" />
          <div>
            <div class="text-left text-uppercase d-block d-sm-none">
              {{ $t("cartSummary.grossTotal") }}
            </div>
            <div class="price font-weight-bold">
              {{ $n(item.grossTotal, "currency") }}
            </div>
          </div>
          <v-btn
            class="remove-from-card-btn"
            icon
            aria-label="Rimuovi dal carrello"
            @click.stop.prevent="remove"
            @mousedown.stop
            v-if="
              !(
                item.product.productInfos &&
                item.product.productInfos.USER_DISABLED == '1'
              )
            "
          >
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>

    <!-- <v-checkbox
      v-if="full && item.editable"
      dense
      color="primary"
      hide-details
      class="accept-alternatives my-1 pt-0 px-4 px-md-2"
      v-model="acceptAlternative"
      @click="!acceptAlternative ? openAlternativeProductsModal() : null"
    > -->
    <v-checkbox
      v-if="
        full &&
          productsAlternatives &&
          productsAlternatives[item.product.productId] &&
          productsAlternatives[item.product.productId].length > 0 &&
          item.editable
      "
      dense
      color="primary"
      hide-details
      class="accept-alternatives my-1 pt-0 px-4 px-md-2"
      v-model="acceptAlternative"
    >
      <template v-slot:label>
        <span>{{ $t("cartDetail.acceptAlternative") }}</span>
      </template>
    </v-checkbox>
    <v-btn
      v-if="
        productsAlternatives &&
          productsAlternatives[item.product.productId] &&
          productsAlternatives[item.product.productId].length > 0
      "
      text
      color="primary"
      @click="openAlternativeProductsModal"
    >
      {{ $t("checkout.alternativeProduct.cta") }}
    </v-btn>
    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <!-- <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div> -->
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="primary white--text"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
#checkout-items-list {
  .v-list-item {
    padding: 0;
  }
}
#cart-right-drawer {
  .v-list-item {
    padding: 0 8px;
  }
}
.cart-item {
  .v-list-item::after {
    min-height: 0;
  }
  .price {
    min-width: 75px;
    font-size: 20px;
    width: 75px;
  }
  .promo-wrapper-product-card {
    position: initial;
    // .product_promo {
    //   margin-right: 4px;
    //   padding: 2px;
    //   position: relative;
    // }
  }
  .old-price {
    font-size: 14px !important;
  }
  .cur-price {
    font-size: 18px !important;
  }
  .product-qty {
    width: 160px;
    min-width: 160px;
    .qty-wrap.not-empty .minus {
      background-color: $white !important;
    }
    .qty-wrap.not-empty .plus {
      background-color: $white !important;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-list-item__action {
      width: 100%;
    }
  }
  .v-list-item.v-list-item--active.v-list-item--link.theme--light:before {
    opacity: 0;
  }
}
.is-cordova {
  .cart-item {
    .product-qty {
      margin: 0px 8px;
    }
  }
}
@media (max-width: 400px) {
  .cart-item {
    .remove-from-card-btn {
      position: absolute;
      top: 12px;
      right: 4px;
    }
    .v-list-item__content {
      margin-right: 38px;
    }
  }
}
</style>
<script>
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";
import get from "lodash/get";
import ProductAlternative from "@/components/product/ProductAlternative.vue";
import { mapActions, mapGetters } from "vuex";

import {
  isOnlyCharacterAndNumber,
  maxLength
} from "~/validator/validationRules";
import ProductPrice from "../product/ProductPrice.vue";

export default {
  name: "CartItem",
  mixins: [productMixin],
  components: {
    ProductQty,
    ProductPrice
  },
  props: {
    full: { type: Boolean, default: false },
    item: { type: Object, required: true },
    productsAlternatives: { type: Object, required: false }
  },
  data() {
    return {
      removeDialog: false,
      notesRule: [
        isOnlyCharacterAndNumber(
          "Sono ammessi solamente caratteri alfanumerici."
        ),
        maxLength(200, "max 200 caratteri")
      ]
    };
  },
  computed: {
    // productHasAlternative() {
    //   return (
    //     this.productsAlternatives &&
    //     this.productsAlternatives[this.item.product.productId] &&
    //     this.productsAlternatives[this.item.product.productId].length > 0
    //   );
    // },
    product() {
      return this.item.product;
    },
    isInPromo() {
      // show also warehousePromoTypeId 4 because is about load points and doesn't apply a discount on price
      return (
        this.item.discount > 0 ||
        (this.item.product.warehousePromo &&
          this.item.product.warehousePromo.warehousePromoTypeId == 4)
      );
    },
    itemAdjustment() {
      return this.getOrderAdjustmentByItemId(this.item.itemId);
    },
    ...mapGetters({
      getOrderAdjustmentByItemId: "cart/getOrderAdjustmentByItemId"
    }),
    acceptAlternative: {
      get() {
        return get(this.item, "cartItemInfo.accept_alternatives") == "true";
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    }
  },
  methods: {
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo",
      updatedDrawerRight: "app/updatedDrawerRight"
    }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    },
    openAlternativeProductsModal() {
      this.$dialog.show(ProductAlternative, {
        product: this.item.product,
        waitForResult: true,
        width: 960,
        fullscreen: this.$vuetify.breakpoint.xsOnly
      });
    }

    // openAlternativeProductsModal() {
    //   this.$dialog.show(ProductAlternative, {
    //     product: {
    //       id: 790972,
    //       searchUid: "4b0ffa53-2bee-42f0-bd86-58e321abd348",
    //       productId: 790972,
    //       codInt: "107959901",
    //       codVar: "",
    //       code: "107959901",
    //       codeVariant: "",
    //       name: "FAGIOLI CUCINATI IN SALSA DI POMODORO",
    //       shortDescr: "HEINZ",
    //       description: "415g",
    //       pubState: 1,
    //       slug: "fagioli-cucinati-in-salsa-di-pomodoro-415g-heinz",
    //       itemUrl: "/product/fagioli-cucinati-in-salsa-di-pomodoro-415g-heinz",
    //       barcode: "5000157024671",
    //       productNatureId: 1,
    //       productHierarchyLevelId: 1,
    //       vendor: {
    //         vendorId: 5379,
    //         name: "HEINZ"
    //       },
    //       categoryId: 45052,
    //       ivaCategory: {
    //         ivaCategoryId: 3,
    //         code: "22",
    //         descr: "",
    //         ivaPct: "0.22"
    //       },
    //       productInfos: {
    //         MINACQ: "1",
    //         WEB_ENABLED: "1",
    //         CHECKED: "true",
    //         TIPOLOGIA: "Pezzo"
    //       },
    //       dayLock: "",
    //       productClasses: [],
    //       metaData: {
    //         product_product_seo: {},
    //         product_info: {}
    //       },
    //       media: [
    //         {
    //           type: "front",
    //           small:
    //             "/photo/2024/12/09/188031/main/thumb/0000000000943282.webp",
    //           medium:
    //             "/photo/2024/12/09/188031/main/photo/0000000000943282.webp",
    //           large: "/photo/2024/12/09/188031/main/large/0000000000943282.webp"
    //         },
    //         {
    //           type: "other_4",
    //           small: "/photo/2024/12/09/188031/T70/thumb/00156781_T70.webp",
    //           medium: "/photo/2024/12/09/188031/T70/photo/00156781_T70.webp",
    //           large: "/photo/2024/12/09/188031/T70/large/00156781_T70.webp"
    //         },
    //         {
    //           type: "other_5",
    //           small: "/photo/2024/12/09/188031/T71/thumb/00156781_T71.webp",
    //           medium: "/photo/2024/12/09/188031/T71/photo/00156781_T71.webp",
    //           large: "/photo/2024/12/09/188031/T71/large/00156781_T71.webp"
    //         },
    //         {
    //           type: "other_6",
    //           small: "/photo/2024/12/09/188031/T72/thumb/00156781_T72.webp",
    //           medium: "/photo/2024/12/09/188031/T72/photo/00156781_T72.webp",
    //           large: "/photo/2024/12/09/188031/T72/large/00156781_T72.webp"
    //         },
    //         {
    //           type: "other_7",
    //           small: "/photo/2024/12/09/188031/T73/thumb/00156781_T73.webp",
    //           medium: "/photo/2024/12/09/188031/T73/photo/00156781_T73.webp",
    //           large: "/photo/2024/12/09/188031/T73/large/00156781_T73.webp"
    //         },
    //         {
    //           type: "other_8",
    //           small: "/photo/2024/12/09/188031/T74/thumb/00156781_T74.webp",
    //           medium: "/photo/2024/12/09/188031/T74/photo/00156781_T74.webp",
    //           large: "/photo/2024/12/09/188031/T74/large/00156781_T74.webp"
    //         },
    //         {
    //           type: "other_9",
    //           small: "/photo/2024/12/09/188031/T75/thumb/00156781_T75.webp",
    //           medium: "/photo/2024/12/09/188031/T75/photo/00156781_T75.webp",
    //           large: "/photo/2024/12/09/188031/T75/large/00156781_T75.webp"
    //         }
    //       ],
    //       media_attachments: {},
    //       mediaURL: "/photo/2024/12/09/188031/main/thumb/0000000000943282.webp",
    //       mediaURLMedium:
    //         "/photo/2024/12/09/188031/main/photo/0000000000943282.webp",
    //       price: 999,
    //       priceId: 11878035,
    //       currency: {
    //         currencyTypeId: 2,
    //         name: "Euro",
    //         currencySymbol: "",
    //         codInt: ""
    //       },
    //       priceDisplay: 999,
    //       priceUnitDisplay: "pz",
    //       available: 100000000,
    //       selectOptions: [],
    //       breadCrumbs: [
    //         {
    //           name: "Specialità dal mondo",
    //           slug: "specialita-dal-mondo",
    //           itemUrl: "/specialita-dal-mondo"
    //         },
    //         {
    //           name: "Cucina messicana",
    //           slug: "specialita-dal-mondo/cucina-messicana",
    //           itemUrl: "/specialita-dal-mondo/cucina-messicana"
    //         },
    //         {
    //           name: "Cucina messicana",
    //           slug: "specialita-dal-mondo/cucina-messicana/cucina-messicana",
    //           itemUrl: "/specialita-dal-mondo/cucina-messicana/cucina-messicana"
    //         }
    //       ]
    //     },
    //     waitForResult: true,
    //     width: 960,
    //     fullscreen: this.$vuetify.breakpoint.xsOnly
    //   });
    // }
  }
};
</script>
